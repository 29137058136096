<template>
  <div class="main-content">
    <h3 class="marginBottom20">数据看板</h3>
    <div class="flex align-items flex-wrap">
      <div class="marginRight10 marginBottom10 width200">
        <el-select
          size="medium"
          v-model="searchData.cityId"
          clearable
        >
          <el-option
            label="市"
            value=""
          >
          </el-option>
          <el-option
            v-for="item in cityData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="marginRight10 marginBottom10">
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
        >查询</el-button>
      </div>
      <div class="marginRight10 marginBottom10">
        <el-button
          size="medium"
          type="info"
          icon="el-icon-refresh-right"
          @click="handleReset"
        >重置</el-button>
      </div>
    </div>

    <div class="flex align-items justify-content-space-between flex-wrap">
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日已成交二手房</p>
        <p class="text">买方人数</p>
        <!--        <h1 class="num numOne">{{ info.todayDealSecondBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.todayDealSecondBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日已成交租户人数</p>
        <p class="text"></p>
        <!--        <h1 class="num numOne">{{ info.todayDealTenantBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.todayDealTenantBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日已成交新房</p>
        <p class="text">买方人数</p>
        <!--        <h1 class="num numOne">{{ info.todayDealNewBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.todayDealNewBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日二手房成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayDealSecondNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayDealSecondNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日新房成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayDealNewNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayDealNewNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日办公租赁成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayDealTenantNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayDealTenantNum || 0"></avue-count-up></p>
      </div>
      <!--      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">-->
      <!--        <p class="text">今日客户新房咨询量</p>-->
      <!--        <p class="text"></p>-->
      <!--&lt;!&ndash;        <h1 class="num numTwo">{{ info.todayCustomerNewInfoNum || 0 }}</h1>&ndash;&gt;-->
      <!--        <p class="num numTwo"><avue-count-up :end="info.todayCustomerNewInfoNum || 0"></avue-count-up></p>-->
      <!--      </div>-->
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日客户二手房咨询量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayCustomerSecondInfoNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayCustomerSecondInfoNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日客户办公租赁咨询量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayCustomerTenantInfoNum }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayCustomerTenantInfoNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日新增用户量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayAddUserNum }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayAddUserNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日带看量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayLookNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayLookNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日录入二手房房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayAddSecondNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayAddSecondNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日录入办公租赁房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayAddTenantNum }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayAddTenantNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">今日录入新房房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.todayAddNewNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.todayAddNewNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 paddingTop20 paddingBottom20 marginBottom20"></div>
    </div>
    <hr>
    <div class="flex align-items justify-content-space-between flex-wrap marginTop20">
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计已成交二手房</p>
        <p class="text">买方人数</p>
        <!--        <h1 class="num numOne">{{ info.totalDealSecondBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.totalDealSecondBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计已成交租户人数</p>
        <p class="text"></p>
        <!--        <h1 class="num numOne">{{ info.totalDealTenantBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.totalDealTenantBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计已成交新房</p>
        <p class="text">买方人数</p>
        <!--        <h1 class="num numOne">{{ info.totalDealNewBuyerNum || 0 }}</h1>-->
        <p class="num numOne"><avue-count-up :end="info.totalDealNewBuyerNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计二手房成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalDealSecondNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalDealSecondNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计新房成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalDealNewNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalDealNewNum || 0"></avue-count-up></p>
      </div>
      <!--      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">-->
      <!--        <p class="text">累计客户新房咨询量</p>-->
      <!--        <p class="text"></p>-->
      <!--&lt;!&ndash;        <h1 class="num numTwo">{{ info.totalCustomerNewInfoNum || 0 }}</h1>&ndash;&gt;-->
      <!--        <p class="num numTwo"><avue-count-up :end="info.totalCustomerNewInfoNum || 0"></avue-count-up></p>-->
      <!--      </div>-->
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计客户二手房咨询量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalCustomerSecondInfoNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalCustomerSecondInfoNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计客户办公租赁咨询量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalCustomerTenantInfoNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalCustomerTenantInfoNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计办公租赁成交量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalDealTenantNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalDealTenantNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计新增用户量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalAddUserNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalAddUserNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计带看量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalLookNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalLookNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计录入二手房房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalAddSecondNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalAddSecondNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计录入办公租赁房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalAddTenantNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalAddTenantNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 border paddingTop20 paddingBottom20 marginBottom20">
        <p class="text">累计录入新房房源量</p>
        <p class="text"></p>
        <!--        <h1 class="num numTwo">{{ info.totalAddNewNum || 0 }}</h1>-->
        <p class="num numTwo"><avue-count-up :end="info.totalAddNewNum || 0"></avue-count-up></p>
      </div>
      <div class="width300 paddingTop20 paddingBottom20 marginBottom20"></div>
    </div>
  </div>
</template>

<script>
import { view } from "@/api/data/board";
import { cityList } from "@/api/build/manage";
export default {
  name: "index",
  data() {
    return {
      info: {},
      cityData: [],
      searchData: {
        cityId: "",
      },
    };
  },
  mounted() {
    this.getView();
    this.cityList();
  },
  methods: {
    handleSearch() {
      this.getView();
    },
    handleReset() {
      this.searchData.cityId = "";
      this.getView();
    },
    async getView() {
      try {
        const { data } = await view({ cityId: this.searchData.cityId });
        // console.log(data)
        this.info = data;
      } catch (error) {
        console.log(error);
      }
    },
    async cityList() {
      const { data } = await cityList();
      this.cityData = data || [];
    },
  },
};
</script>

<style scoped>
.flex:after {
  content: "";
  width: 300px;
  height: 0;
  visibility: hidden;
}
.text {
  height: 22px;
  font-family: MicrosoftYaHei, 微软雅黑, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: rgb(102, 102, 102);
  text-align: center;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  text-transform: none;
  letter-spacing: normal;
}
.border {
  border: 1px solid rgba(170, 170, 170, 1);
  border-radius: 10px;
}
.num {
  font-family: MicrosoftYaHei, 微软雅黑, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  text-align: center;
}
.numOne {
  color: rgb(245, 154, 35);
}
.numTwo {
  color: rgb(0, 0, 255);
}
</style>